// shades of gray
$white: #ffffff;
$gray-100: #f9f9fb;
$gray-200: #E2E2E2;
$gray-300: #F0F2F3;
$gray-400: #c4c4c4;
$gray-500: #4E4E4E;
$gray-600: #e8e8e8;
$gray-700: #f2f2f7;
$gray-800: #4E4E4E;
$gray-900: #2B2B2B;
$black: #000000;


// color-variables start
$primary: #00AAEE;
$green: #4096DD;
// color-variables end

//Fonts sizes start
$font-size-base: 1rem;
$font-size-xxl: $font-size-base * 1.25;
$font-size-xl: $font-size-base * 1.063;
$font-size-lg: $font-size-base * 1.125;
$font-size-sm: $font-size-base * 0.875;
$font-size-xs: $font-size-base * 0.813;

$h1-font-size: $font-size-base * 2.375;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;
//Fonts sizes end
